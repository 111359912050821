import React from 'react'
import { Link } from 'gatsby'
import IndexLayout from '../../layouts'

const ThankYou = (props) => (
  <IndexLayout>
    <div className="container mt-4">
      <div className="row">
        <div className="col-12">
          <h2>Thanks for your review!</h2>
          <p>
            We're always working to make our recipes better and to do a better job in giving you the best possible food!
            Always feel free to reach out to us at hey@elmfoods.com
        </p>
        </div>

      </div>
      <div className="row pt-4 d-print-none">
        <div className="col-12 text-center">
          <Link to="/shop-all-recipes" >
            Browse Other Recipes
                </Link>
        </div>
      </div>
    </div>
  </IndexLayout>
)

export default ThankYou
